<template>
  <div class="noselect margins3" style="align-self: start">
    <div>
      <h3 class="pointer" @click="followLink('/peoplelist')">Люди</h3>
      <h3 class="pointer" @click="followLink('/moneypersonal')">Мои финансы</h3>
      <h3 class="pointer" @click="followLink('/eventcalendar')">Календарь событий</h3>
      <h3
        v-if="this.$root?.user?.roles.includes('hradmin')"
        class="pointer"
        @click="followLink('/bariskahistory')"
      >
        Путь бариски
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    followLink(href) {
      this.$router.push(href);
    },
  },
};
</script>
